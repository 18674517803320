import { getMessageFromError } from 'ha/utils/getMessageFromError';
import { STATE_NAME, Actions } from '../constants';

const defaultCase = (state, action) => {
  // @todo this part is deprecated, because doesn't give us right control on the alerts
  // at the moment, to avoid breaking changes I added a skipAlert property
  // that we can check in the action

  // this is for preventing redux-form errors opening our alerts
  if (action.type.startsWith('@@redux-form')) {
    return state;
  }

  const metaAlert = action.meta && action.meta.alert;
  const isError =
    (action.type.endsWith && action.type.endsWith('.error')) ||
    action.error ||
    (metaAlert && metaAlert.type === 'error');

  const shouldSkip = metaAlert && metaAlert.skip;

  if (isError && !shouldSkip) {
    const errorData =
      action.payload.errors || action.payload.error || action.payload;
    const errors = Array.isArray(errorData) ? errorData : [errorData];
    const alerts = errors.map(error => ({
      id: new Date().getTime(),
      text: getMessageFromError(error),
      kind: 'danger',
    }));

    return [...state, ...alerts];
  }

  return state;
};

// eslint-disable-next-line default-param-last
export const alertsReducer = (state = [], action) => {
  switch (action.type) {
    case Actions.SEND:
      return [...state, action.payload];
    case Actions.SEND_ALL:
      return [...state, ...action.payload];
    case Actions.DISMISS:
      return state.filter(alert => alert.id !== action.payload);
    case Actions.CLEAR:
      return [];
    default:
      return defaultCase(state, action);
  }
};

const getState = state => state[STATE_NAME];

export const getAlerts = getState;
export const getCurrentAlert = state => getState(state)[0];
