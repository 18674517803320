import { IdentityVerificationStatus } from '@ha/api/v2/types/User';

export const STATE_NAME = 'authLogic';

export enum AuthLogicTypes {
  updateUser = 'AUTHLOGIC/UPDATE_USER',
  initUser = 'INIT_USER',
  fulfillImpersonation = 'AUTHLOGIC/FULFILL_IMPERSONATION',
}

export enum FetchUserBillingInfoTypes {
  start = 'AUTHLOGIC/FETCH_USER_BILLING_INFO_START',
  done = 'AUTHLOGIC/FETCH_USER_BILLING_INFO_SUCCESS',
  fail = 'AUTHLOGIC/FETCH_USER_BILLING_INFO_FAIL',
}

export enum ImpersonateUserTypes {
  start = 'AUTHLOGIC/IMPERSONATION_START',
  done = 'AUTHLOGIC/IMPERSONATION_DONE',
  fail = 'AUTHLOGIC/IMPERSONATION_FAIL',
}

export enum ProfileEditActionTypes {
  loadUserStart = 'PROFILE_EDIT/LOAD_USER_START',
  loadUserFail = 'PROFILE_EDIT/LOAD_USER_FAIL',
  loadUserDone = 'PROFILE_EDIT/LOAD_USER_DONE',
  updateUser = 'PROFILE_EDIT/UPDATE_USER',
  submitFormFail = 'PROFILE_EDIT/SUBMIT_FORM_FAIL',
  updatePendingChanges = 'PROFILE_EDIT/UPDATE_PENDING_CHANGES',
}

export const ProfileEditActions = {
  LOAD_USER_START: ProfileEditActionTypes.loadUserStart,
  LOAD_USER_DONE: ProfileEditActionTypes.loadUserDone,
  LOAD_USER_FAIL: ProfileEditActionTypes.loadUserFail,

  UPDATE_USER: ProfileEditActionTypes.updateUser,
  SUBMIT_FORM_FAIL: ProfileEditActionTypes.submitFormFail,

  UPDATE_PENDING_CHANGES: ProfileEditActionTypes.updatePendingChanges,
};

export const newPayReqCreationDismissed = 'new_pay_req_creation_dismissed';
export const newPayReqOverviewDismissed = 'new_pay_req_overview_dismissed';

export const conversationAccessFeature = 'conversation-access';
export const paymentRequestAccessFeature = 'payment-request-access';

export const CONFIRMED_IDENTITY_VERIFICATION_STATES = [
  IdentityVerificationStatus.Verified,
  IdentityVerificationStatus.PreVerified,
  IdentityVerificationStatus.VerifiedByAdmin,
];

export const BILLING_INFO_STORAGE_KEY = 'billing_information';
