import { Dispatch, AnyAction } from 'redux';

import { AppServices } from 'ha/services/getAppServices';
import { Client } from '@twilio/conversations';
import { NetworkError } from '../types';

export function initRealtimeService(debug = false) {
  return (
    _: Dispatch<AnyAction>,
    __: () => void,
    { apiV2, twilio }: AppServices,
  ): Promise<Client> =>
    apiV2.getRealtimeToken('me').then(({ data, status }) => {
      if (data?.token) {
        return twilio.init(data.token, {
          logLevel: debug ? 'error' : 'silent',
        });
      }

      const err = new Error('Twilio Token is not provided') as NetworkError;

      err.status = status;

      return Promise.reject(err);
    });
}
