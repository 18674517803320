import { parseAuthCookies } from '@ha/auth/common/cookie';
import { AUTH_REFRESH_URL } from '@ha/auth/common/routes';

export const ACCESS_TOKEN_REFRESH_LOCK = `__ha_refresh_lock__`;

interface TokenConfig {
  refresh_uri?: string;
}

export class Token {
  // refresh token is httpOnly which means we can't access it on client-side
  // if access token is not present we can't differentiate whether the user is anonymous or token is expired and needs to be refreshed
  // so we stop attempting refresh if it failed with 401 code (no refresh token cookie was present)
  private stopRefresh = false;

  private config: Required<TokenConfig>;

  constructor(config: TokenConfig = {}) {
    this.config = {
      refresh_uri:
        config.refresh_uri ?? `${window.location.origin}${AUTH_REFRESH_URL}`,
    };
  }

  async getAuthorizationHeader(): Promise<string | undefined> {
    const accessToken = await this.getAccessToken();

    return accessToken ? `Bearer ${accessToken}` : undefined;
  }

  private async getAccessToken(): Promise<string | undefined> {
    const { accessToken } = parseAuthCookies(document.cookie);

    if (accessToken) {
      // we resume refresh logic in case refresh was done by the other tab
      this.stopRefresh = false;
      return accessToken;
    }

    if (this.stopRefresh) {
      return undefined;
    }

    return this.refreshWithLock();
  }

  private async refreshWithLock(): Promise<string | undefined> {
    if (!navigator.locks) {
      await import('navigator.locks');
    }

    // eslint-disable-next-line compat/compat
    return navigator.locks.request(ACCESS_TOKEN_REFRESH_LOCK, () =>
      this.refresh(),
    );
  }

  private async refresh(): Promise<string | undefined> {
    try {
      if (this.stopRefresh) {
        return undefined;
      }

      const { accessToken } = parseAuthCookies(document.cookie);
      if (accessToken) {
        return accessToken;
      }

      const response = await fetch(this.config.refresh_uri, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      });
      // to remove the "Caution: request is not finished yet" warning in Chrome DevTools
      // https://stackoverflow.com/questions/45816743/how-to-solve-this-caution-request-is-not-finished-yet-in-chrome
      await response.text();

      if (!response.ok) {
        if (response.status === 401) {
          this.stopRefresh = true;
        }
        throw new Error(`Failed to refresh token: ${response.statusText}`);
      }

      // cookies were updated by the /oauth/refresh request
      return parseAuthCookies(document.cookie).accessToken as string;
    } catch (_err) {
      return undefined;
    }
  }
}
