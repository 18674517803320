import scroller from 'react-scroll/modules/mixins/scroller';

const scrollOptions = { offset: -200, smooth: true };

export function scrollMiddleware(/* state */) {
  return next => action => {
    next(action);

    const { meta } = action;

    if (!meta || !meta.scrollTo) return;

    if (meta.scrollTo) {
      scroller.scrollTo(`position-${meta.scrollTo}`, scrollOptions);
    }
  };
}
