import type { MakeCall } from '../createMakeCall';
import { Currency } from '../types/Currencies';
import { PayoutTypes } from '../types/PayoutMethods';

export enum PayoutTransactionStatus {
  ALL = 'all',
  UPCOMING = 'upcoming',
  ON_HOLD = 'on-hold',
  PAID = 'paid',
  BOUNCED = 'bounced',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export interface Transaction {
  id: number;
  amount: number;
  currency: Currency;
  status: PayoutTransactionStatus;
  settlementReference: string;
  type: number;
  actualPayoutDate?: string;
  additionalInformation?: string;
  expectedPayoutDate: string;
  engagement?: {
    uuid: string;
    startDate: string;
    endDate: string;
    type: string;
    conversationId: number;
  };
  invoice?: {
    number: string;
    url: string;
  };
  payoutMethod?: {
    guid: string;
    accountDetails: string;
    accountName: string;
    accountType: PayoutTypes;
  };
  tenant?: {
    id: number;
    uuid: string;
    firstName: string;
    lastName: string;
  };
}

export type GetPayoutTransactionsResponse = {
  payouts: Transaction[];
  totalCount: number;
};

export type GetPayoutTransactionsQuery = {
  fromDate?: string;
  limit?: number;
  offset?: number;
  status?:
    | `${PayoutTransactionStatus}`
    | `${PayoutTransactionStatus},${PayoutTransactionStatus}`
    | `${PayoutTransactionStatus},${PayoutTransactionStatus},${PayoutTransactionStatus}`; // supports multiple coma-separated payout statuses
  excludeStatus?:
    | `${PayoutTransactionStatus}`
    | `${PayoutTransactionStatus},${PayoutTransactionStatus}`
    | `${PayoutTransactionStatus},${PayoutTransactionStatus},${PayoutTransactionStatus}`; // supports multiple coma-separated payout statuses
  tenancy?: string;
  toDate?: string;
  type?: number; // supports multiple coma-separated payout types
  settlementReference?: string;
  orderBy: string;
};

const getPayoutTransactions =
  (makeCall: MakeCall) =>
  (
    userId: number,
    query: GetPayoutTransactionsQuery,
  ): Promise<{ data: GetPayoutTransactionsResponse; status: number }> =>
    makeCall<GetPayoutTransactionsResponse>(
      'GET',
      `/api/v2/user/${userId}/payout-transactions`,
      {
        query,
      },
    );

export { getPayoutTransactions };

export type GetPayoutTransactions = ReturnType<typeof getPayoutTransactions>;
