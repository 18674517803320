import { MakeCall } from './createMakeCall';
import { User, UserDefaultsUpdatePayload } from './types/User';

export type UpdateUserPayload = Partial<
  Omit<User, 'spokenLanguages' | 'defaults'> & {
    spokenLanguages: string;
    defaults: Partial<UserDefaultsUpdatePayload>;
    haUserReviewStars?: number;
    haUserReviewText?: string;
  }
>;

export type UserIdType = number | 'me';

const updateUser =
  (makeCall: MakeCall) => (userId: UserIdType, data: UpdateUserPayload) =>
    makeCall<User>('PUT', `/api/v2/user/${userId}`, {
      data,
    });

export { updateUser };
