import { MakeCall } from './createMakeCall';
import { ConversationsCounters } from './types/ConversationsCounters';

export interface Query {
  listingId?: number;
}

export type CountersResponse = ConversationsCounters;

const getConversationCounters =
  (makeCall: MakeCall) => (userId: number, query?: Query) =>
    makeCall<CountersResponse>(
      'GET',
      `/api/v2/conversations/counters/user/${userId}`,
      { query },
    );
export { getConversationCounters };
