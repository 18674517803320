import { LoaderFunction, redirect } from 'react-router-dom';

import { Store } from 'redux';

import { HttpStatus } from 'ha/constants/HttpStatus';

import { getIsAdvertiser, getUserType } from 'ha/modules/AuthLogic/selectors';

export type UserRole = 'ADVERTISER' | 'TENANT';

export const createRolesGuardLoader = (store: Store) => {
  const state = store.getState();
  const userType = getUserType(state);

  return (allowedRoles: UserRole[]): LoaderFunction =>
    () => {
      if (!userType) return null;

      const isAdvertiser = getIsAdvertiser(state);
      const userRole = isAdvertiser ? 'ADVERTISER' : 'TENANT';
      if (!allowedRoles.includes(userRole)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw redirect('/404', {
          status: HttpStatus.NOT_FOUND,
        });
      }
      return null;
    };
};
