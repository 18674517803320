import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from 'ha/i18n';

import { impersonateUser } from '../actions';
import { getImpersonatingAdmin } from '../selectors';

interface ImpersonatingAdmin {
  id: number;
  name: string;
}

interface ChildrenProps {
  impersonatingAdmin: ImpersonatingAdmin | null;
  onStop: () => void;
}

interface Props {
  children: React.FunctionComponent<ChildrenProps>;
}

export const useImpersonationInformation = () => {
  const { urlResolver } = useIntl();

  const dispatch = useDispatch();
  const impersonatingAdmin = useSelector(getImpersonatingAdmin);

  const [impersonatingAdminState, setImpersonatingAdminState] =
    React.useState<ImpersonatingAdmin | null>(null);

  const stopImpersonation = React.useCallback(() => {
    dispatch(impersonateUser(0, urlResolver.getAdminPagesUrl()));
  }, [dispatch, urlResolver]);

  React.useEffect(() => {
    setImpersonatingAdminState(impersonatingAdmin);
  }, [impersonatingAdmin]);

  return {
    impersonatingAdmin: impersonatingAdminState,
    onStop: stopImpersonation,
  };
};

export function Impersonation({ children }: Props) {
  const impersonationInformation = useImpersonationInformation();

  return children(impersonationInformation);
}
