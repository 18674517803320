import { page } from '@hbf/analytics';

import { Thunk } from 'ha/myredux/types';

import { setRemarketingTag } from './setRemarketingTag';

interface Params {
  category: string;
  name: string;
  traits: object;
  remarketingTag?: object;
}

export const FIRST_TIME_USERS = { 'First Time': 'FALSE' };

export const onPageMount =
  ({ category, name, traits, remarketingTag }: Params): Thunk =>
  (dispatch, getState, { analytics }) =>
    analytics.onUserIdentified(() => {
      // @ts-expect-error - category 'string' is not assignable to parameter of type 'apiObject'. Wrong page function type reference
      page(category, name, traits);

      if (remarketingTag) {
        setRemarketingTag(remarketingTag);
      }
    });
