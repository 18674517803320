import { TopicCluster } from '@ha/contentful';
import { Language, defineKey as T } from '@ha/intl';

export const BlogColors = {
  orange: {
    // Todo(@dyaa): Replace with the correct color when new palette is ready
    400: '#BB4F3B',
  },
};

export const TOPIC_CLUSTER_LABEL: {
  [key in TopicCluster]: string;
} = {
  rentingAbroad: T('community.categories.rentingAbroad'),
  movingAbroad: T('community.categories.movingAbroad'),
  livingIn: T('community.categories.livingIn'),
  studying: T('community.categories.studying'),
  working: T('community.categories.working'),
  beforeTenancy: T('blog.topic.before_tenancy'),
  duringTenancy: T('blog.topic.during_tenancy'),
  housingTrends: T('blog.topic.housing_trends'),
  rentalLaws: T('blog.topic.rental_laws'),
  investing: T('blog.topic.investing'),
  preRenting: T('blog.topic.pre_renting'),
  rentingOut: T('blog.topic.renting_out'),
  laws: T('blog.topic.laws'),
  news: T('blog.topic.news'),
  caseStudies: T('blog.topic.case_studies'),
  productNews: T('blog.topic.product_news'),
  helpCenter: T('blog.topic.help_center'),
};

export const landlordBlogPath = {
  [Language.en]: 'blog/landlords/United-Kingdom',
  [Language.de]: 'blog/vermieter/Deutschland',
  [Language.nl]: 'blog/verhuurders/Nederland',
  [Language.it]: 'blog/proprietario/Italia',
  [Language.es]: 'blog/propietarios/España',
};

export const LANDLORDS_BLOG_SLUG = '/blog/landlords';
