import type { OverridedMixpanel as Mixpanel } from 'mixpanel-browser';
import { Cookies } from 'ha/constants/Cookies';
import { setInStorage } from 'ha/utils/storage';
import { reportError } from 'ha/helpers/bugReporter/reportError';
import { CookieClient } from 'ha/services/cookie';

const timeoutMs = 60000;
const intervalMs = 1000;

export const setupAjsDistinctId = (
  mixpanel: Mixpanel,
  cookieClient: CookieClient,
): void => {
  const saveDistinctId = () => {
    try {
      const distinctId = mixpanel.get_distinct_id();

      // Card #3094. A part of proof-of-concept. Must be generalized in case of validness.
      // Supplying registration events with Mixpanel's distinct_id.
      cookieClient.set(Cookies.AJS_DISTINCT_ID, distinctId, {
        domain: window?.location?.hostname,
      });

      setInStorage(Cookies.AJS_DISTINCT_ID, distinctId);
    } catch (error) {
      reportError('Mixpanel loading failed', {
        metaData: {
          error,
        },
      });
    }
  };

  // __loaded property is set by Mixpanel script when its instance is initialised
  // https://github.com/mixpanel/mixpanel-js/blob/master/src/mixpanel-core.js#L260
  // @ts-ignore
  if (mixpanel.__loaded) {
    saveDistinctId();
    return;
  }
  // for some reasons mixpanel is not always in loaded state on rudderstack.ready for a few users
  // try to update the value in the background as soon as it loads
  const startTime = Date.now();

  const intervalId = setInterval(() => {
    if (Date.now() - startTime > timeoutMs) {
      clearInterval(intervalId);
      return;
    }

    // @ts-ignore
    if (!mixpanel.__loaded) {
      return;
    }

    saveDistinctId();
    clearInterval(intervalId);
  }, intervalMs);
};
