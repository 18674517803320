import { Dispatch } from 'redux';
import { Action } from '@housinganywhere/safe-redux';
import { ActionTypes } from '../actions';

function handleChangeLocation(nextUrl: string) {
  global.location.assign(nextUrl);
}

function handleReplaceLocation(nextUrl: string) {
  global.location.replace(nextUrl);
}

export function urlLocationMiddleware() {
  return () =>
    (next: Dispatch) =>
    (action: Action<string, { nextLocation: string }>) => {
      if (action?.type === ActionTypes.CHANGE) {
        handleChangeLocation(action.payload.nextLocation);
      }
      if (action?.type === ActionTypes.REPLACE) {
        handleReplaceLocation(action.payload.nextLocation);
      }
      next(action);
    };
}
