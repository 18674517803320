import { ThunkMiddleware } from 'redux-thunk';
import { AnyAction } from 'redux';

import { asyncRetry } from 'ha/utils/asyncRetry';
import { GlobalState } from 'ha/types/store';
import { AppServices } from 'ha/services/getAppServices';

import { Client, ConnectionState, Message, State } from '@twilio/conversations';
import {
  initRealtimeService,
  realtimeConnectionUpdated,
  receiveMessage,
} from '../actions';
import { dontRetryHttps } from '../constants';
import { NetworkError } from '../types';
import { MessageAttributes } from '../actions/receiveMessage';

interface Props {
  debug: boolean;
  onError: (e: Error) => void;
  getChannelId: (x: GlobalState) => string;
}

const shouldRetry = (error: NetworkError) =>
  !dontRetryHttps.includes(error.status);

const realtimeMessagingMiddleWare = ({
  debug,
  onError,
  getChannelId,
}: Props): ThunkMiddleware<GlobalState, AnyAction, AppServices> => {
  let initialized = false;

  return ({ dispatch, getState }) =>
    next =>
    action => {
      next(action);

      const channelId = getChannelId(getState());

      if (channelId && !initialized) {
        initialized = true;

        const initDispatch = () => dispatch(initRealtimeService(debug));

        asyncRetry<Client, NetworkError>(initDispatch, { shouldRetry })
          .then((client: Client) => {
            client.on('stateChanged', (state: State) => {
              if (state === 'initialized') {
                client.on(
                  'connectionStateChanged',
                  (connectionState: ConnectionState) => {
                    dispatch(realtimeConnectionUpdated(connectionState));
                  },
                );

                client
                  .getConversationByUniqueName(channelId)
                  .then(conversation => {
                    conversation.on('messageAdded', (message: Message) => {
                      if (message.body) {
                        dispatch(
                          receiveMessage({
                            attributes:
                              (message.attributes as unknown as MessageAttributes) ||
                              {},
                            body: JSON.parse(message.body),
                          }),
                        );
                      }
                    });
                  });
              }
            });
          })
          .catch(error => {
            if (
              error &&
              ((error.status && !dontRetryHttps.includes(error.status)) ||
                !error.status)
            ) {
              onError(error);
            }
          });
      }
    };
};
export { realtimeMessagingMiddleWare };
