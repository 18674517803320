import { reset } from '@hbf/analytics';

import { removeFromStorage } from 'ha/utils/storage';

import { savedSearchLocalStore } from 'ha/modules/SavedSearches/savedSearchLocalStore';

import { BILLING_INFO_STORAGE_KEY } from './constants';

export function onSignOut() {
  window.addEventListener('beforeunload', () => {
    reset();
    globalThis?.mixpanel?.reset?.();

    removeFromStorage(BILLING_INFO_STORAGE_KEY);

    // removed Saved Search from localStorage once user logs out, so next time user can start with a clean slate
    savedSearchLocalStore.remove();
  });
}
