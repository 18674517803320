import { LoaderFunctionArgs } from 'react-router-dom';

import { getTenantsGlobalBlogPath } from '@ha/contentful';
import { Language } from '@ha/intl';

import { PricingUrls } from 'ha/constants/PricingPageSlug';

import { formatHrefURL } from 'ha/utils/formatHrefURL';

import { landlordBlogPath } from 'ha/modules/Blog/constants';
import { PREV_TO_NEXT_HELP_CENTER_PATHS } from 'ha/pages/HelpCenter/constants';

export interface RedirectRoute {
  path: string;
  getNextPath: (args: LoaderFunctionArgs) => string;
  redirectCode?: number;
}

const pillarPagesRedirectedRoutes = [
  {
    path: '/nl/Nederland/woning-verhuren',
    getNextPath: () => '/nl/Nederland/verhuren-zonder-makelaar',
  },
  {
    path: '/nl/Nederland/investeren-in-vastgoed',
    getNextPath: () =>
      '/nl/Nederland/de-beste-steden-om-als-vastgoedbelegger-te-investeren',
  },
  {
    path: '/moving-abroad',
    getNextPath: () => '/living-abroad-benefits',
  },
  {
    path: '/renting-abroad',
    getNextPath: () => '/avoid-online-scams',
  },
  {
    path: '/de/Deutschland/immobilien-investment',
    getNextPath: () => '/de/Deutschland/strategien-zur-immobilienfinanzierung',
  },
  {
    path: '/de/Deutschland/vermietung',
    getNextPath: () => '/de/Deutschland/wohnung-vermieten-berlin',
  },
];

export const localizedListOfRedirectedRoutes: RedirectRoute[] = [
  ...pillarPagesRedirectedRoutes,
  {
    path: '/university-plans',
    getNextPath: () => 'https://info.housinganywhere.com/universities',
  },
  {
    path: '/admin/conversation',
    getNextPath: ({ request }) => {
      const url = new URL(request.url);
      return `/admin/conversations/${url.searchParams.get('cid')}`;
    },
  },
  {
    path: '/my/payment-form',
    getNextPath: ({ request }) => {
      const url = new URL(request.url);

      return `/my/talk/${url.searchParams.get('id')}/book`;
    },
  },
  {
    path: '/amsterdam-cost-of-living',
    getNextPath: () => '/Amsterdam--Netherlands/amsterdam-cost-of-living',
  },
  // We don't have PT and ZH in contentful, but CTA on Home page in these languages leads to 404
  // We might remove that page in the future at all, so far we set redirects to EN
  {
    path: '/pt/become-an-advertiser',
    getNextPath: () => '/become-an-advertiser',
  },
  {
    path: '/zh/become-an-advertiser',
    getNextPath: () => '/become-an-advertiser',
  },
  {
    path: '/nl/become-an-advertiser',
    getNextPath: () => '/nl/verhuurder-worden',
  },
  {
    path: '/fr/become-an-advertiser',
    getNextPath: () => '/fr/devenir-annonceur',
  },
  {
    path: '/es/become-an-advertiser',
    getNextPath: () => '/es/hazte-anunciante',
  },
  {
    path: '/it/become-an-advertiser',
    getNextPath: () => '/it/dai-in-affitto',
  },
  {
    path: '/de/become-an-advertiser',
    getNextPath: () => '/de/inserent-werden',
  },
  // temporarily redirect old payment-methods route to the new one
  {
    path: '/my/payment-methods',
    getNextPath: () => '/my/payments/methods',
  },
  {
    path: '/my/payment-requests',
    getNextPath: () => '/my/payouts/payment-requests',
  },
  {
    path: '/how-to-book',
    getNextPath: () => '/renting',
  },
  {
    path: '/pricing',
    getNextPath: () => '/pricing/tenants',
  },
  {
    path: '/rentradar',
    getNextPath: () => 'https://rentradar.housinganywhere.com/',
  },
  {
    path: '/nl/pricing/tenants',
    getNextPath: () => `/nl${PricingUrls.tenant.nl}`,
  },
  {
    path: '/de/pricing/tenants',
    getNextPath: () => `/de${PricingUrls.tenant.de}`,
  },
  {
    path: '/es/pricing/tenants',
    getNextPath: () => `/es${PricingUrls.tenant.es}`,
  },
  {
    path: '/fr/pricing/tenants',
    getNextPath: () => `/fr${PricingUrls.tenant.fr}`,
  },
  {
    path: '/it/pricing/tenants',
    getNextPath: () => `/it${PricingUrls.tenant.it}`,
  },
  {
    path: '/pt/pricing/tenants',
    getNextPath: () => `/pt${PricingUrls.tenant.pt}`,
  },
  {
    path: '/zh/pricing/tenants',
    getNextPath: () => PricingUrls.tenant.zh,
    redirectCode: 302,
  },
  {
    path: '/nl/pricing/landlords',
    getNextPath: () => `/nl${PricingUrls.landlord.nl}`,
  },
  {
    path: '/de/pricing/landlords',
    getNextPath: () => `/de${PricingUrls.landlord.de}`,
  },
  {
    path: '/es/pricing/landlords',
    getNextPath: () => `/es${PricingUrls.landlord.es}`,
  },
  {
    path: '/fr/pricing/landlords',
    getNextPath: () => `/fr${PricingUrls.landlord.fr}`,
  },
  {
    path: '/it/pricing/landlords',
    getNextPath: () => `/it${PricingUrls.landlord.it}`,
  },
  {
    path: '/pt/pricing/landlords',
    getNextPath: () => `/pt${PricingUrls.landlord.pt}`,
  },
  {
    path: '/zh/pricing/landlords',
    getNextPath: () => PricingUrls.landlord.zh,
    redirectCode: 302,
  },

  {
    path: '/Germany/health-insurance',
    getNextPath: () => '/Germany/health-insurance-in-germany',
  },
  {
    path: '/Germany/practical-info-for-living',
    getNextPath: () => '/Germany/living-in-germany',
  },
  {
    path: '/top-internships-germany',
    getNextPath: () => '/Germany/top-internships-germany',
  },
  {
    path: '/Rotterdam--Netherlands/accommodation',
    getNextPath: () => '/s/Rotterdam--Netherlands',
    redirectCode: 301,
  },
  {
    path: '/fr/Rotterdam--Pays-Bas/logement',
    getNextPath: () => '/fr/s/Rotterdam--Pays-Bas',
    redirectCode: 301,
  },
  {
    path: '/de/Rotterdam--Niederlande/Unterkunft',
    getNextPath: () => '/de/s/Rotterdam--Niederlande',
    redirectCode: 301,
  },
  {
    path: '/es/R%C3%B3terdam--Holanda/alojamiento',
    getNextPath: () => '/es/s/Róterdam--Holanda',
    redirectCode: 301,
  },
  {
    path: '/it/Rotterdam--Olanda/sistemazione',
    getNextPath: () => '/it/s/Rotterdam--Olanda',
    redirectCode: 301,
  },
  {
    path: '/nl/Rotterdam--Nederland/Kamers',
    getNextPath: () => '/nl/s/Rotterdam--Nederland',
    redirectCode: 301,
  },
  {
    path: '/:city--:country/accommodation',
    getNextPath: ({ params }) => {
      const { city, country } = params;

      return `/s/${city}--${country}`;
    },
    redirectCode: 301,
  },
  {
    path: '/my/payouts/verify-identity/*',
    getNextPath: () => '/my/payouts/verify-identity',
  },
  {
    path: '/data-security',
    getNextPath: () => '/privacy-policy',
    redirectCode: 301,
  },
  {
    path: '/ua/*',
    getNextPath: ({ params }) => `/uk/${params['*'] ? params['*'] : ''}`,
  },
  ...Object.keys(Language).map((lang: Language) => ({
    path: formatHrefURL('/faq-commission', lang),
    getNextPath: () => formatHrefURL('/pricing/landlords', lang),
  })),
  ...Object.keys(PREV_TO_NEXT_HELP_CENTER_PATHS).map((lang: Language) => ({
    path: formatHrefURL(PREV_TO_NEXT_HELP_CENTER_PATHS[lang].prev, lang),
    getNextPath: () =>
      formatHrefURL(PREV_TO_NEXT_HELP_CENTER_PATHS[lang].next, lang),
  })),
];

export const nonLocalizedListOfRedirectedRoutes = (
  langPrefix: string,
): RedirectRoute[] => [
  {
    path: 'support-ukraine/search/*',
    getNextPath: () => '/support-ukraine',
  },
  {
    path: 'faq',
    getNextPath: () => '/answers',
  },
  {
    path: 'unsubscribe',
    getNextPath: () => '/my/subscriptions',
  },
  {
    path: 'search/*',
    getNextPath: ({ params }) => `${langPrefix}s/${params['*']}`,
  },
  {
    path: 'hello/*',
    getNextPath: ({ params }) => `/h/${params['*']}`,
  },
  {
    path: 'city/*',
    getNextPath: ({ params }) => `${langPrefix}${params['*']}`,
  },
  {
    path: 'how-to-list',
    getNextPath: () => '/become-an-advertiser',
  },
  {
    path: 'agb',
    getNextPath: () => '/terms',
  },
  {
    path: 'countries/*',
    getNextPath: ({ params }) => `${langPrefix}${params['*']}`,
  },
  {
    path: 'lander/*',
    getNextPath: ({ params }) => `${langPrefix}${params['*']}`,
  },
  {
    path: 'paises/*',
    getNextPath: ({ params }) => `${langPrefix}${params['*']}`,
  },
  {
    path: 'pays/*',
    getNextPath: ({ params }) => `${langPrefix}${params['*']}`,
  },
  {
    path: 'paesi/*',
    getNextPath: ({ params }) => `${langPrefix}${params['*']}`,
  },
  {
    path: 'landen/*',
    getNextPath: ({ params }) => `${langPrefix}${params['*']}`,
  },
  {
    path: 'billing-subscription',
    getNextPath: () => `${langPrefix}subscription/tenant`,
  },
  {
    path: 'r/*',
    getNextPath: ({ params }) => `${langPrefix}room/${params['*']}`,
  },
  {
    path: 'my/profile/:userID',
    getNextPath: ({ params }) => `${langPrefix}users/show/${params.userID}`,
  },
  {
    path: 'my/payout-methods',
    getNextPath: () => `${langPrefix}my/payouts/preferences`,
  },
  {
    path: 'community', // old blog page
    getNextPath: () => getTenantsGlobalBlogPath(),
  },
  {
    path: 'padova--Italy/unipd/sign-up', // url error on partner's side
    getNextPath: () => '/Padua--Italy/unipd/sign-up',
  },
];

const getCommunityRedirectedRoute = ([path, lang]: [
  string,
  Language,
]): RedirectRoute => ({
  path: formatHrefURL(path, lang),
  getNextPath: () => formatHrefURL(`/${landlordBlogPath[lang]}`, lang),
});

export const communityRedirectedRoutes = [
  ['/United-Kingdom/landlords', Language.en],
  ['/Deutschland/vermieter', Language.de],
  ['/Nederland/verhuurders', Language.nl],
  ['/Italia/proprietario', Language.it],
].map(getCommunityRedirectedRoute);
