import { reportError } from 'ha/helpers/bugReporter/reportError';

export function createCrashReporter(stateSanitizerFn) {
  return store => next => action => {
    // eslint-disable-line consistent-return
    try {
      next(action);
    } catch (error) {
      let stateToSend = store.getState();

      if (typeof stateSanitizerFn === 'function') {
        stateToSend = stateSanitizerFn(stateToSend);
      }

      reportError(error, {
        metaData: {
          action: JSON.stringify(action),
          state: JSON.stringify(stateToSend),
        },
      });
    }
  };
}
