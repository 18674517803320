import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/sv';

import { Language, Locale } from '@ha/intl';
import type { Logger } from '@hbf/log';

import { Thunk } from 'ha/myredux/types';

import { getIsAdmin, getUser, getUserIdAlias } from '../selectors';

import { fetchImpersonationInfo } from './fetchImpersonationInfo';
import { fetchUser } from './fetchUser';
import { impersonateUser } from './impersonateUser';

const IMPERSONATION_QUERY_PARAMETER = 'id2';

export function initSession(
  lang: Language,
  logger?: Logger,
  referrer?: string,
): Thunk {
  return async (dispatch, getState) => {
    const localeFromLanguage = Locale[lang];

    dayjs.locale(localeFromLanguage);

    // @todo IDP replace with check for access token presence.
    if (!process.browser) {
      await dispatch(fetchUser(logger, referrer));

      return;
    }

    const state = getState();

    const user = getUser(state);

    // impersonation user if impersonation query parameter is present

    const isAdmin = getIsAdmin(state);

    if (isAdmin) {
      const urlObject = new URL(window.location.href);

      const impersonationTargetUserId = urlObject.searchParams.get(
        IMPERSONATION_QUERY_PARAMETER,
      );

      if (impersonationTargetUserId) {
        urlObject.searchParams.delete(IMPERSONATION_QUERY_PARAMETER);

        await dispatch(
          impersonateUser(
            parseInt(impersonationTargetUserId, 10),
            urlObject.toString(),
          ),
        );

        // action above implies redirect, no need to continue
        return;
      }
    }

    // load impersonation information

    if (user) {
      await dispatch(fetchImpersonationInfo());
    }

    // setup hotjar

    window.hj?.('identify', user ? getUserIdAlias(state) : null, {});

    // setup unless

    if (window.Txt) {
      const userType = user ? user?.type || 'tenant' : 'anonymous';

      const userData = [
        {
          key: 'userType',
          value: userType,
          type: 'string',
          custom: true,
        },
      ];

      if (user) {
        window.Txt.identify(
          `${user.uuid}@user.housinganywhere.com`,
          userData,
          user.uuid,
        );
      } else {
        window.Txt.updateProfile(userData);
      }
    }
  };
}
