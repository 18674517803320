export enum Cookies {
  ADVANCED_PRICING_BANNER_SHOWN = 'advanced-pricing-banner-shown',
  ADVERTISER_REVIEWS_ANSWER = 'ha-advertiser-reviews-answer',
  AJS_DISTINCT_ID = 'ajs_distinct_id',
  CONSISTENCY_TOKEN = 'ha_cnst',
  FEATURES = 'ha-feature-flags',
  HA_SESSION = 'ha_session',
  INSURANCE_BANNER_SHOWN = 'InsuranceBannerShown',
  KRUGER_VISITED = 'kruger_visited',
  MESSAGES_TRANSLATE_LANGUAGE = 'messages-translate-language',
  MULTI_UNIT_BANNER_SHOWN = 'multi-unit-listing-banner-show',
  RENT_RADAR_ANSWER = 'ha-rent-radar-answer',
  ROOM_JUST_CREATED = 'room_just_created',
  STUDENT_RESIDENCE_NOTICE_BANNER_SHOWN = 'student_residence_notice_banner_search',
  USE_CONTRACT_TYPE_FILTER = 'use_contract_type_filter',
  USE_DATES_FILTER = 'use_dates_filter',
  USER_COUNTRY = 'ha_user_country',
  USER_IDENTIFIER = 'ha_anonymous_id',
  USER_MESSAGE = 'ha_um',
}

export type BookingLinkType = `bookinglink_${string}`;

export type CookiesNames = Cookies | BookingLinkType;

enum CookiesTime {
  ONE_DAY = 1,
  SEVEN_DAYS = 7,
  ONE_YEAR = 365,
}

// Undefined is equal to Session
export const CookiesExpireTime: Partial<
  Record<keyof typeof Cookies, CookiesTime>
> = {
  USER_IDENTIFIER: CookiesTime.ONE_YEAR,
  USER_COUNTRY: CookiesTime.SEVEN_DAYS,
  FEATURES: undefined,
};
