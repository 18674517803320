import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { Thunk } from 'ha/myredux/types';
import { ConversationsCounters } from '@ha/api/v2/types/ConversationsCounters';

import { ActionTypes } from '../constants';

export const inboxCountersActions = {
  START: () => createAction(ActionTypes.START),
  DONE: (inboxCounters: ConversationsCounters) =>
    createAction(ActionTypes.DONE, inboxCounters),
  FAIL: () => createAction(ActionTypes.FAIL),
};

export type Actions = ActionsUnion<typeof inboxCountersActions>;

export const getInboxCounters =
  (userId: number): Thunk =>
  (dispatch, _, { apiV2 }) => {
    dispatch(inboxCountersActions.START());

    return apiV2
      .getConversationCounters(userId)
      .then(({ data }) => {
        dispatch(inboxCountersActions.DONE(data));
      })
      .catch(() => {
        dispatch(inboxCountersActions.FAIL());
      });
  };
