import { PayoutTransactionStatus } from '@ha/api/v2/payments/getPayoutTransactions';
import { defineKey } from '@ha/intl';

export const STATE_NAME = 'transactionsHistory';
export const TRANSACTIONS_PER_PAGE = 10;
export const REFERENCE_NUM_LEN = 9;

export const payoutDelayAnswerArticle = {
  id: 5639539,
  slug: 'why-was-my-payout-delayed',
};

export enum Status {
  All = 'all',
  Completed = 'completed',
  Upcoming = 'upcoming',
}

export const STATUS_GROUP_MAP = {
  [Status.Upcoming]: {
    backgroundColor: 'neutral.50',
    color: 'neutral.50',
    group: [
      PayoutTransactionStatus.BOUNCED,
      PayoutTransactionStatus.FAILED,
      PayoutTransactionStatus.ON_HOLD,
      PayoutTransactionStatus.UPCOMING,
    ],
    label: defineKey('label.scheduled'),
    value: Status.Upcoming,
  },
  [Status.Completed]: {
    backgroundColor: 'success.light',
    color: 'success.main',
    group: [PayoutTransactionStatus.PAID],
    label: defineKey('Paid out'),
    value: Status.Completed,
  },
  [Status.All]: {
    backgroundColor: 'neutral.50',
    color: 'neutral.50',
    group: [PayoutTransactionStatus.ALL],
    label: defineKey('transactions.filter.all_transactions'),
    value: Status.All,
  },
};

export const statusTabsOrder = [
  STATUS_GROUP_MAP[Status.Upcoming],
  STATUS_GROUP_MAP[Status.Completed],
  STATUS_GROUP_MAP[Status.All],
];
