import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { defineKey as T } from '@ha/intl';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { Thunk } from 'ha/myredux/types';

import { send as sendAlert } from 'ha/modules/Alertbar/actions';

const ERROR_MESSAGE = T(
  'There was an error removing your favorite listing. Please try again later',
);

export enum ActionTypes {
  START = '[favorites] REMOVE_FAVORITE',
  DONE = '[favorites] REMOVE_FAVORITE_DONE',
  FAIL = '[favorites] REMOVE_FAVORITE_FAIL',
}

export const Actions = {
  start: (listingId: number) => createAction(ActionTypes.START, listingId),
  done: (listingId: number) => createAction(ActionTypes.DONE, listingId),
  fail: (listingId: number) => createAction(ActionTypes.FAIL, listingId),
};
export type ActionsType = ActionsUnion<typeof Actions>;

export const removeFavoriteListing =
  (unitTypeId: number): Thunk =>
  (dispatch, getState, { apiV2 }) => {
    dispatch(Actions.start(unitTypeId));

    return apiV2
      .removeFavoriteUnitType(unitTypeId)
      .then(() => {
        dispatch(Actions.done(unitTypeId));
      })
      .catch(error => {
        reportError('Error removing favourtie listing', {
          metaData: { error },
        });
        dispatch(
          sendAlert({
            text: ERROR_MESSAGE,
            kind: 'danger',
            dismissAfter: 5000,
          }),
        );
        dispatch(Actions.fail(unitTypeId));
      });
  };
