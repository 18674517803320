import * as Sentry from '@sentry/react';

interface ErrorObj {
  name: string;
  message: string;
}

type Teams = 'payments';

interface Options {
  metaData?: Record<string, any>;
  severity?: 'info' | 'warning' | 'error';
  tags?: Record<string, any> & { team?: Teams };
}

export type ReportError = Error | ErrorObj | string;

function parseErrorMessage(error: ReportError) {
  if (error instanceof Error) return { error };

  if (typeof error === 'string') return { error: new Error(error) };

  return { error: new Error(error.message), extra: { originalError: error } };
}

export function reportError(e: ReportError, options?: Options) {
  const { error, extra } = parseErrorMessage(e);

  Sentry.captureException(error, {
    tags: options?.tags,
    extra: { ...extra, ...options?.metaData },
    level: options?.severity || 'error',
  });

  if (process.browser) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
