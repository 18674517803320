import { Language } from '@ha/intl';

import { Gender } from './Gender';
import { ListingCancellationPolicy, ListingContractType } from './Listing';
import { QualificationRequirementDefault } from './qualifications/QualificationRequirement';

export enum UserType {
  UNVERIFIED = '',
  PENDING = 'pending',
  QUARATINE = 'quarantine',
  /**
   * @deprecated When setting this value, you should use the new property landlordType with values from the `LandlordType` enum instead.
   */
  STUDENT = 'student',
  LANDLORD = 'landlord',
  UNDER_REVIEW = 'under-review',
}

export enum LandlordType {
  STUDENT = 'student',
  PRIVATE_LANDLORD = 'private-landlord',
  RENTAL_COMPANY = 'rental-company',
  STUDENT_COMPANY = 'student-company',
  OTHER = 'other',
}

export enum IdentityVerificationStatus {
  Failed = 'failed',
  Pending = 'pending',
  PreVerified = 'pre-verified',
  Verified = 'verified',
  VerifiedByAdmin = 'verified-by-admin',
}

export interface User {
  avatarURL: string;
  birthDate: string;
  countryCode: string;
  phoneCountryCode: string;
  createdAt: string;
  description: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  hasFacebookProfile: boolean;
  hasPhone: boolean;
  id: number;
  uuid: string;
  email: string;
  isAdmin: boolean;
  isPartner: boolean;
  isEmailVerified: boolean;
  isTrusted: boolean;
  lastActivityAt: string;
  location: string;
  nationality: string;
  responseRate: number;
  responseTime: number;
  smoker: string;
  study: string;
  type: UserType;
  uniId: number;
  originUniId: number;
  isVerifiedTenant: boolean;
  isCorporateTenant: boolean;
  work: string;
  phone: string;
  lang: Language;
  isAliased: boolean;
  notificationLang: string;
  occupation: string;
  occupationDetail: string;
  enableSMSNotifications: boolean;
  landlordType: LandlordType;
  landlordTypeOther: string;
  spokenLanguages: string[];
  enableBookingLink: boolean;
  logoURL: string;
  isManagedAccount: boolean;
  defaults?: UserDefaults;
  countryOfResidence: string;
  roomsUnderManagement: string;
  isBusinessAccount: boolean;
  hasReviewedHA: boolean;
  idVerificationStatus: IdentityVerificationStatus | '';
}

export interface UserDefaults {
  minimumStayMonths: number;
  maximumStay: number;
  administrationFeeType: 'percent-of-price' | 'fixed';
  administrationFeeValue: number;
  administrationFeeCurrency: string;
  contractType: ListingContractType;
  cancellationPolicy: ListingCancellationPolicy;
  requirements?: QualificationRequirementDefault;
}

export interface UserDefaultsUpdatePayload extends UserDefaults {
  adminFeeSetAsDefault: boolean;
  adminFeeApplyToExisting: boolean;
  contractTypeSetAsDefault: boolean;
  contractTypeApplyToExisting: boolean;
  cancellationPolicySetAsDefault: boolean;
  cancellationPolicyApplyToExisting: boolean;
  rentalPeriodApplyToExisting: boolean;
  rentalPeriodSetAsDefault: boolean;
}

export type PublicUser = Pick<
  User,
  | 'avatarURL'
  | 'birthDate'
  | 'countryCode'
  | 'createdAt'
  | 'description'
  | 'enableBookingLink'
  | 'firstName'
  | 'gender'
  | 'hasFacebookProfile'
  | 'hasPhone'
  | 'id'
  | 'isEmailVerified'
  | 'isTrusted'
  | 'isVerifiedTenant'
  | 'lastActivityAt'
  | 'logoURL'
  | 'location'
  | 'nationality'
  | 'occupation'
  | 'responseRate'
  | 'responseTime'
  | 'smoker'
  | 'spokenLanguages'
  | 'study'
  | 'type'
  | 'uniId'
  | 'uuid'
  | 'work'
  | 'hasReviewedHA'
>;
