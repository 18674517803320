export const STATE_NAME = 'experiments';

export enum ActionTypes {
  SET_VARIANTS = 'EXPERIMENTS/SET_VARIANTS',
  PLAY_EXPERIMENT = 'EXPERIMENTS/PLAY_EXPERIMENT',
}

// https://housinganywhere.atlassian.net/browse/TX-2639
export const RankingExperimentWhitelistedCities = [
  'berlin',
  'barcelona',
  'madrid',
  'milan',
  'munich',
  'lisbon',
  'budapest',
  'rotterdam',
  'valencia',
];
