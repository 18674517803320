import { BaseError } from './BaseError';

export interface ErrorResponse {
  errors?: { code: string }[];
}

/**
 * Error class for an error raised trying to make an API call
 *
 * @class RequestError
 * @access public
 */
export class RequestError<
  Response extends Record<string, unknown> = Record<string, unknown>,
> extends BaseError {
  headers: Headers;

  constructor(
    public status: number,
    message: string,
    cause: Error,
    {
      response = {},
      headers = new Headers(),
    }: {
      response?: ErrorResponse;
      headers?: Headers;
    } = {},
  ) {
    super(message, cause, response);
    this.name = 'RequestError';
    this.response = response as Response;
    this.headers = headers;
  }
}
