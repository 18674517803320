import { initialState as initialAuthLogicState } from 'ha/modules/AuthLogic';

export function getInitialState({ preloadedState = {}, language }) {
  const alerts = preloadedState.alerts || [];

  return {
    ...preloadedState,
    authLogic: {
      ...initialAuthLogicState,
      ...preloadedState.authLogic,
      language,
    },
    languageSwitcher: {
      ...preloadedState.languageSwitcher,
      currentLanguage: {
        code: language,
      },
    },
    alerts,
  };
}
