/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';

import { WEEK_IN_MS } from '@ha/date';
import {
  ListingPricingValues,
  ListingRentalPeriodType,
} from '@ha/api/v2/types/Listing';

import { getExclusion } from './exclusionDate/getExclusion';
import { NormalizedListing, AlgoliaListingRequiredSubset } from '../Types';

interface NormalizeOptions {
  currency: string;
  currencyRate: number;
}

export const normalizePricingValues = ({
  currencyRate,
  item,
}: {
  currencyRate: NormalizeOptions['currencyRate'];
  item: AlgoliaListingRequiredSubset;
}) => {
  const pricingValuesEUR: Partial<ListingPricingValues> = {};

  if (!item.pricingValuesEUR) return pricingValuesEUR;

  pricingValuesEUR.flat = Math.round(currencyRate * item.pricingValuesEUR.flat);

  if (item.pricingValuesEUR.monthly) {
    pricingValuesEUR.monthly = Object.entries(
      item.pricingValuesEUR.monthly,
    ).reduce((acc, curr) => {
      const [month, value] = curr;
      return {
        ...acc,
        [month]: Math.round(currencyRate * value),
      };
    }, {}) as ListingPricingValues['monthly'];
  }

  if (item.pricingValuesEUR?.precise) {
    pricingValuesEUR.precise = Object.entries(
      item.pricingValuesEUR?.precise,
    ).reduce((acc, curr) => {
      const [monthKey, value] = curr;

      // skip past price
      const [month, year] = monthKey.split('-'); // monthKey is 'MM-YYYY'
      if (dayjs(new Date(`${year}-${month}`)).isBefore(dayjs(), 'month')) {
        return acc;
      }

      return {
        ...acc,
        [monthKey]: Math.round(currencyRate * value),
      };
    }, {});
  }

  return pricingValuesEUR;
};

export const normalizeListing =
  ({ currency, currencyRate }: NormalizeOptions) =>
  <T extends AlgoliaListingRequiredSubset>(item: T): NormalizedListing<T> => {
    const photos = item.photos || [];

    return {
      ...item,

      // TODO: Refactor components so only one id is used
      id: item.internalID,
      entityId: item.internalID,
      advertiser: { avatarURL: item.advertiserAvatar },
      currency,
      listingPath: item.path,
      previewImage: item.photos?.[0],
      price: Math.round(currencyRate * item.priceEUR * 100),
      minPrice: Math.round(currencyRate * item.minPrice),
      maxPrice: Math.round(currencyRate * item.maxPrice),

      isNew: Date.now() - item.creationDateTS * 1000 < WEEK_IN_MS,
      minimalRentalPeriod: item.minimumStayMonths,
      exclusions: [getExclusion(item.dateFrom, item.dateTo)],

      latitude: (item._geoloc && item._geoloc.lat) || null,
      longitude: (item._geoloc && item._geoloc.lng) || null,

      photos,

      unitTypeId: item.unitTypeInternalID,
      rentalPeriodType:
        item.rentalPeriodType === 'strict'
          ? ListingRentalPeriodType.STRICT
          : ListingRentalPeriodType.FLEXIBLE,

      ...(item.pricingValuesEUR
        ? { pricingValuesEUR: normalizePricingValues({ currencyRate, item }) }
        : {}),
    };
  };
