import { MakeCall } from './createMakeCall';
import { Conversation } from './types/Conversation';

type UserId = number | 'me';
export type ConversationOrder = string;
export type ConversationFilterDate = 'future' | 'current' | 'past';

export interface Query {
  /**
   * @description comma-separated string with possible values: `stateHistory`, `messages`, `listing`, `advertiser`, `tenant`, `tenancy`, `rentalConditions`,
   */
  expand?: string;
  excludeMoveOutOlderThan?: number;
  asAdvertiser?: boolean;
  state?:
    | 'all'
    | 'archived'
    | 'expired'
    | 'favorited'
    | 'inbox'
    | 'paid'
    | 'paidAndCancelled'
    | 'pending'
    | 'unread';
  limit?: number;
  listingId?: string | number;
  unitTypeId?: string | number;
  asTenant?: boolean;
  offset?: number;
  order?: ConversationOrder;
  text?: string;
  futureDates?: boolean;
  filterDate?: ConversationFilterDate;
  uuids?: string;
}

export interface ConversationsResponse {
  conversations: Conversation[];
  count: number;
}

const getConversations =
  (makeCall: MakeCall) => (userId: UserId, query?: Query) =>
    makeCall<ConversationsResponse>(
      'GET',
      `/api/v2/conversations/user/${userId}`,
      {
        query,
      },
    );
export { getConversations };
