import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { User } from '@ha/api/v2/types/User';

import { Thunk } from 'ha/myredux/types';

import { changeLocation } from 'ha/modules/URLLocationHandler';

import { ImpersonateUserTypes } from '../constants';
import { onSignOut } from '../onSignOut';

type Id = number;

export const Actions = {
  start: (id: Id) => createAction(ImpersonateUserTypes.start, id),
  done: (data: User) => createAction(ImpersonateUserTypes.done, data),
  fail: () => createAction(ImpersonateUserTypes.fail),
};

export type ActionsType = ActionsUnion<typeof Actions>;

export const impersonateUser =
  (id: Id, nextUrl: string): Thunk =>
  (dispatch, _, { apiV2 }) => {
    dispatch(Actions.start(id));

    return apiV2
      .impersonateUser(id)
      .then(({ data }) => {
        dispatch(Actions.done(data));

        onSignOut();

        dispatch(changeLocation(nextUrl));
      })
      .catch(() => {
        dispatch(Actions.fail());
      });
  };
