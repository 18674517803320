import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

export enum ActionTypes {
  CHANGE = 'URL_LOCATION_HANDLER/CHANGE_LOCATION',
  REPLACE = 'URL_LOCATION_HANDLER/REPLACE_LOCATION',
}

export const Actions = {
  change: (nextLocation: string) =>
    createAction(ActionTypes.CHANGE, { nextLocation }),
  replace: (nextLocation: string) =>
    createAction(ActionTypes.REPLACE, { nextLocation }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
