import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { defineKey as T } from '@ha/intl';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { AuthPageQueryActions, AuthPageTarget } from 'ha/helpers/UrlResolver';
import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { Thunk } from 'ha/myredux/types';
import { setInStorage } from 'ha/utils/sessionStorage';

import { send as sendAlert } from 'ha/modules/Alertbar/actions';
import { changeLocation } from 'ha/modules/URLLocationHandler';

import { STORAGE_KEY } from '../constants';
import { isLoading } from '../selectors';

const ERROR_MESSAGE = T(
  'There was an error adding your favorite listing. Please try again later',
);

export enum ActionTypes {
  START = '[favorites] ADD_FAVORITE',
  DONE = '[favorites] ADD_FAVORITE_DONE',
  FAIL = '[favorites] ADD_FAVORITE_FAIL',
}

export const Actions = {
  start: (listingId: number) => createAction(ActionTypes.START, listingId),
  done: (listingId: number) => createAction(ActionTypes.DONE, listingId),
  fail: (listingId: number) => createAction(ActionTypes.FAIL, listingId),
};
export type ActionsType = ActionsUnion<typeof Actions>;

export const addFavoriteListing =
  (unitTypeId: number, returnUrl?: string): Thunk =>
  async (dispatch, getState, { intl, apiV2 }) => {
    const state = getState();

    if (!getIsAuthenticated(state)) {
      setInStorage(STORAGE_KEY, unitTypeId);

      dispatch(
        changeLocation(
          intl.urlResolver.getOAuthSignupUrl(returnUrl, {
            target: AuthPageTarget.Tenant,
            action: AuthPageQueryActions.ADD_FAVORITE_LISTING,
          }),
        ),
      );
      return;
    }

    if (isLoading(state, unitTypeId)) {
      return;
    }

    dispatch(Actions.start(unitTypeId));

    try {
      await apiV2.addFavoriteUnitType(unitTypeId);

      dispatch(Actions.done(unitTypeId));
    } catch (error) {
      reportError('Error adding favourite listing', { metaData: { error } });
      dispatch(
        sendAlert({
          text: ERROR_MESSAGE,
          kind: 'danger',
          dismissAfter: 5000,
        }),
      );
      dispatch(Actions.fail(unitTypeId));
    }
  };
