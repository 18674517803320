import { AnalyticsConfig, init, setAnonymousId } from '@hbf/analytics';

import { Config } from 'ha/config';
import { Cookies } from 'ha/constants/Cookies';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { STRICTLY_NECESSARY_DESTINATIONS } from 'ha/modules/Analytics/constants';
import { getFromStorage } from 'ha/utils/storage';

import { CookieClient } from '../cookie';

import {
  DEFAULT_COOKIE_CONSENT_CONFIG,
  LOCK_INTEGRATIONS_VERSION,
} from './constants';
import { setupAjsDistinctId } from './setupAjsDistinctId';
import { Analytics, Callback, CookieConsent } from './types';

const analytics = (
  config: Config['analytics'],
  cookieClient: CookieClient,
): Analytics => {
  const readyCallbacks: Callback[] = [];
  const userDependantCallbacks: Callback[] = [];
  let cookieConsentConfig: CookieConsent;

  let isReady = false;
  let userIdentified = false;

  const runCallbacks = (callbacks: Callback[]) => {
    callbacks.forEach(cb => cb(cookieConsentConfig));
    callbacks.length = 0;
  };

  return {
    init: ({ useBeacon, cookieConsent = DEFAULT_COOKIE_CONSENT_CONFIG }) => {
      cookieConsentConfig = cookieConsent;

      const {
        isConsentGiven,
        isCookieConsentEnabled,
        isAnonymousTrackingEnabled,
        hasUserRejectedAllCategories,
      } = cookieConsentConfig;

      if (!config.enabled) {
        return Promise.resolve();
      }

      const loadOptions: AnalyticsConfig['rudderstack']['loadOptions'] = {
        useBeacon,
        cookieConsentManager: { oneTrust: { enabled: isCookieConsentEnabled } },
        lockIntegrationsVersion: LOCK_INTEGRATIONS_VERSION, // to avoid unexpected bugs with latest versions
      };

      if (isCookieConsentEnabled && !isConsentGiven) {
        loadOptions.integrations = STRICTLY_NECESSARY_DESTINATIONS;
        loadOptions.cookieConsentManager = {
          oneTrust: {
            enabled: false, // to avoid Rudderstack throwing "OneTrust resources are not accessible" error
          },
        };
      }

      return init({
        rudderstack: {
          writeKey: config.rudderStackWriteKey,
          dataPlaneUrl: config.rudderStackDataPlaneURL,
          configUrl: config.rudderStackSourceConfig,
          cdnUrl: config.rudderStackCDN,
          loadOptions,
          onReady: () => {
            // since we're using mixpanel in device mode we need to configure it separately as well
            if (useBeacon && global.mixpanel) {
              global.mixpanel.set_config({ api_transport: 'sendBeacon' });
            }

            if (
              !isCookieConsentEnabled ||
              !isAnonymousTrackingEnabled ||
              !hasUserRejectedAllCategories
            ) {
              try {
                if (global.mixpanel) {
                  setupAjsDistinctId(global.mixpanel, cookieClient);
                }

                // Why do we need this when Rudderstack defines its own anonymousId?
                // It's so we do not lose track of users who have already been identified
                // in Mixpanel but might be navigating the app without being logged in.
                // We all hope to address this very soon.
                let distinctId = cookieClient.get(Cookies.AJS_DISTINCT_ID);

                if (!distinctId) {
                  distinctId = getFromStorage(Cookies.AJS_DISTINCT_ID);

                  if (distinctId) {
                    // if cookie is missing but the value is present in local storage set it as a cookie
                    // so the backend can continue using it
                    // in some browsers (e.g. Safari) cookies can expire in 24h
                    cookieClient.set(Cookies.AJS_DISTINCT_ID, distinctId, {
                      domain: window?.location?.hostname,
                    });
                  }
                }

                if (distinctId) {
                  setAnonymousId(distinctId);
                }
              } catch (error) {
                reportError('Rudderstack - reading Mixpanel cookie ', {
                  metaData: { error },
                });
              }
            }

            runCallbacks(readyCallbacks);

            isReady = true;
          },
        },
      })
        ?.then(() => {
          // eslint-disable-next-line no-console
          console.log('Rudderstack initialized');
        })
        .catch(error => {
          reportError('Rudderstack - init', { metaData: { error } });
        });
    },
    onReady: (callback: Callback) => {
      if (isReady) {
        callback(cookieConsentConfig);
      } else {
        readyCallbacks.push(() => callback(cookieConsentConfig));
      }
    },
    onUserIdentified: (callback: Callback) => {
      if (userIdentified) {
        callback(cookieConsentConfig);
      } else {
        userDependantCallbacks.push(callback);
      }
    },
    userIdentificationComplete: () => {
      runCallbacks(userDependantCallbacks);
      userIdentified = true;
    },
  };
};
export { analytics };
